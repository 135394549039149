// src/components/ProfileDropdown.js
import React, { useState } from 'react';
import { Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import ProfileModal from './ProfileModal';
import useAuth from 'src/hooks/useAuth';
import { useSelector } from 'react-redux';

const ProfileDropdown = () => {
    const { performLogout } = useAuth();
    const { email } = useSelector((state) => state.user);
    const [modalShow, setModalShow] = useState(false);

    const initials = email ? email.charAt(0).toUpperCase() : 'U';

    const profileTooltip = (
        <Tooltip id="profile-tooltip">Profile</Tooltip>
    );

    return (
        <>
            <Dropdown className="d-inline mx-0 profile-dropdown">
                <OverlayTrigger placement="bottom" overlay={profileTooltip}>
                    <Dropdown.Toggle
                        id="profile-dropdown"
                        className="header-droparw"
                        style={{
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            boxShadow: 'none'
                        }}
                    >
                        <div style={{ backgroundColor: '#f5f5f5', borderRadius: '50%', padding: '8px', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1.2rem', color: '#31708f' }}>
                            {initials}
                        </div>
                    </Dropdown.Toggle>
                </OverlayTrigger>
                <Dropdown.Menu className='drop-alignmentProfile' right>
                    <Dropdown.Item onClick={() => setModalShow(true)}>Profile</Dropdown.Item>
                    <Dropdown.Item onClick={performLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <ProfileModal show={modalShow} onHide={() => setModalShow(false)} />
        </>
    );
};

export default ProfileDropdown;
