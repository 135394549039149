// LoadBalancerList.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    Paper,
    IconButton,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    CircularProgress
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import OptimizationDialog from './OptimizationDialog';
import VerificationModal from './VerificationModal';
import { awsService } from 'src/services/awsService';
import { toast } from 'react-hot-toast';

const statusIcons = {
    'running': <CheckCircleOutlineIcon style={{ color: '#2E7D32', marginRight: 8 }} />,
    'stopped': <PauseCircleOutlineIcon style={{ color: '#D32F2F', marginRight: 8 }} />,
    'terminated': <HighlightOffIcon style={{ color: '#757575', marginRight: 8 }} />
};

function LoadBalancerItem({ loadBalancer, expanded, onExpand, onOptimize }) {
    const isStageVerified = loadBalancer.stage === 'STAGE_LB_VERIFIED';

    const isOptimizable = loadBalancer.status === 'OPTIMIZABLE';
    const isOptimizing = loadBalancer.status === 'OPTIMIZING';
    const isNotOptimizable = loadBalancer.status === 'NOT_OPTIMIZABLE';

    // Determine button properties based on stage and status
    let buttonColor = 'primary';
    let buttonText = 'Optimize';
    let buttonDisabled = false;
    let tooltipTitle = 'Click to optimize your load balancer.';
    let showSpinner = false;

    if (!isStageVerified) {
        // Disable button if stage is not verified
        buttonColor = 'inherit';
        buttonText = 'Analysing';
        buttonDisabled = true;
        tooltipTitle = 'Load balancer analysis in progress';
        showSpinner = true;
    } else {
        if (isOptimizing) {
            buttonColor = 'success';
            buttonText = 'Optimizing...';
            buttonDisabled = false;
            tooltipTitle = 'Optimization in progress.';
        } else if (isNotOptimizable) {
            buttonColor = 'inherit';
            buttonText = 'Optimize';
            buttonDisabled = true;
            tooltipTitle = 'This load balancer is not optimizable yet.';
        }
    }

    return (
        <Paper elevation={1} sx={{ mb: 3, overflow: 'hidden', borderRadius: 2, transition: 'box-shadow 0.3s', '&:hover': { boxShadow: 4 } }}>
            <Box sx={{ px: 4, py: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={onExpand} sx={{ mr: 2, color: 'primary.main', p: 0.5 }}>
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                        <Typography variant="h6" sx={{ fontWeight: 500, color: 'text.primary' }}>
                            Name: {loadBalancer.name}
                        </Typography>
                    </Box>
                    <Tooltip title={tooltipTitle} arrow>
                        <span>
                            <Button
                                variant="contained"
                                color={buttonColor}
                                onClick={onOptimize}
                                disabled={buttonDisabled}
                                sx={{
                                    textTransform: 'none',
                                    px: 3,
                                    py: 1,
                                    borderRadius: 1.5,
                                    transition: 'background-color 0.3s',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    ...(isOptimizing && { bgcolor: 'success.main', '&:hover': { bgcolor: 'success.dark' } }),
                                    ...(isNotOptimizable && { bgcolor: 'grey.500', cursor: 'not-allowed' }),
                                    ...(!isStageVerified && { bgcolor: 'grey.500', cursor: 'not-allowed' })
                                }}
                            >
                                {showSpinner && (
                                    <CircularProgress
                                        size={20}
                                        color="inherit"
                                        sx={{ mr: 1 }}
                                    />
                                )}
                                {buttonText}
                            </Button>

                        </span>
                    </Tooltip>
                </Box>
                <Typography variant="body2" sx={{ color: 'text.secondary', ml: 6 }}>
                    {loadBalancer.arn}
                </Typography>
            </Box>
            {expanded && (
                <TableContainer component={Box} sx={{ mt: 1, px: 4, pb: 3 }}>
                    <Table aria-label="instance details">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ borderBottom: '1px solid', borderBottomColor: 'divider', width: '70%', pl: 0, py: 2 }}>
                                    Instance ID
                                </TableCell>
                                <TableCell align="left" sx={{ borderBottom: '1px solid', borderBottomColor: 'divider', width: '30%', pr: 0, py: 2 }}>
                                    Status
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loadBalancer.instances.map((instance) => (
                                <TableRow key={instance.id} sx={{ '&:last-child td': { border: 0 } }}>
                                    <TableCell component="th" scope="row" sx={{ pl: 0, py: 2 }}>
                                        {instance.id}
                                    </TableCell>
                                    <TableCell align="right"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            pr: 0,
                                            py: 2,
                                            '& > *:first-of-type': { marginRight: 1 }
                                        }}>
                                        {statusIcons[instance.status]}
                                        <Typography variant="body2" sx={{ color: 'text.primary' }}>
                                            {instance.status}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Paper>
    );
}

export default function LoadBalancerList() {
    const [expandedLB, setExpandedLB] = useState(null);
    const [loadBalancers, setLoadBalancers] = useState([]);
    const [optimizationResults, setOptimizationResults] = useState(null);
    const [loadingRecommendations, setLoadingRecommendations] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showVerificationModal, setShowVerificationModal] = useState(false);
    const [showOptimizationDialog, setShowOptimizationDialog] = useState(false);
    const [currentLB, setCurrentLB] = useState(null);

    const accountId = useSelector((state) => state.awsAccount.accountId);
    const token = useSelector((state) => state.user.token);
    const region = useSelector((state) => state.awsAccount.region);
    const [loading, setLoading] = useState(false);

    // Ref to keep track of the polling interval
    const pollingRef = useRef(null);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const data = await awsService.fetchLoadBalancers(token, region);
            const mappedData = data.map(lb => ({
                ...lb,
                id: lb.id,
                arn: lb.LoadBalancerArn,
                name: lb.LoadBalancerName,
                type: lb.LoadBalancerType,
                createdTime: new Date(lb.CreatedTime),
                instances: lb.Instances.map(instance => ({
                    id: instance.InstanceId,
                    status: instance.StateName
                })),
                agid: lb.awsmo_group_id,
                stage: lb.awsmo_group_stage,
                status: lb.awsmo_group_status
            }));
            setLoadBalancers(mappedData);
            setLoading(false);

            // Start or stop polling based on whether any load balancer is not verified
            if (mappedData.some(lb => lb.stage !== 'STAGE_LB_VERIFIED')) {
                if (!pollingRef.current) {
                    pollingRef.current = setInterval(fetchData, 30000); // Polling every 30 seconds
                }
            } else {
                if (pollingRef.current) {
                    clearInterval(pollingRef.current);
                    pollingRef.current = null;
                }
            }
        } catch (error) {
            console.error("Failed to fetch load balancers:", error);
            setErrorMessage("Failed to fetch load balancers.");
            setLoading(false);
        }
    }, [token, region]);

    useEffect(() => {
        fetchData();

        return () => {
            if (pollingRef.current) {
                clearInterval(pollingRef.current);
            }
        };
    }, [fetchData, accountId, token, region]);

    const handleOptimizeClick = (loadBalancer) => {
        if (loadBalancer.status === 'OPTIMIZABLE') {
            setCurrentLB(loadBalancer);
            setShowVerificationModal(true);
        } else if (loadBalancer.status === 'OPTIMIZING') {
            setCurrentLB(loadBalancer);
            setOptimizationResults(null);
            setErrorMessage('');
            setShowOptimizationDialog(true);
            fetchOptimizationResults(loadBalancer.agid);
        }
    };

    const fetchOptimizationResults = async (awsmo_group_id) => {
        setLoadingRecommendations(true);
        setErrorMessage('');
        try {
            const results = await awsService.getOptimizationResults(awsmo_group_id);
            setOptimizationResults(results);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setErrorMessage("Your recommendations are being generated. Please check again after some time.");
            } else {
                console.error("Failed to fetch optimization results:", error);
                setErrorMessage("An error occurred while fetching recommendations.");
                toast.error("Failed to fetch optimization recommendations.");
            }
        } finally {
            setLoadingRecommendations(false);
        }
    };

    const handleVerificationConfirm = async () => {
        setShowVerificationModal(false);
        try {
            await awsService.updateAwsmoGroupStatus(currentLB.agid, 'OPTIMIZING', token);
            setLoadBalancers(prevLBs => prevLBs.map(lb => {
                if (lb.id === currentLB.id) {
                    return { ...lb, status: 'OPTIMIZING' };
                }
                return lb;
            }));
            toast.success("Optimization started successfully.");
            setShowOptimizationDialog(true);
            setOptimizationResults(null);
            setErrorMessage('');
            fetchOptimizationResults(currentLB.agid);
        } catch (error) {
            console.error("Failed to update status to OPTIMIZING:", error);
            setErrorMessage("Failed to initiate optimization. Please try again.");
            toast.error("Failed to initiate optimization.");
        }
    };

    const handleVerificationCancel = () => {
        setShowVerificationModal(false);
        setCurrentLB(null);
    };

    const handleStopOptimizing = async () => {
        if (!currentLB) return;
        try {
            await awsService.updateAwsmoGroupStatus(currentLB.agid, 'OPTIMIZABLE', token);
            setLoadBalancers(prevLBs => prevLBs.map(lb => {
                if (lb.id === currentLB.id) {
                    return { ...lb, status: 'OPTIMIZABLE' };
                }
                return lb;
            }));
            toast.success("Optimization stopped successfully.");
            setShowOptimizationDialog(false);
            setOptimizationResults(null);
            setErrorMessage('');
            setCurrentLB(null);
        } catch (error) {
            console.error("Failed to stop optimizing:", error);
            setErrorMessage("Failed to stop optimization. Please try again.");
            toast.error("Failed to stop optimization.");
        }
    };

    return (
        <Box sx={{
            bgcolor: 'background.paper',
            p: 4,
            borderRadius: 2
        }}>
            <Typography
                variant="h5"
                sx={{
                    mb: 4,
                    fontWeight: 500
                }}
            >
                Instances
            </Typography>

            {loadBalancers.length === 0 ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                    <CircularProgress />
                </Box>
            ) : (
                loadBalancers.map((lb) => (
                    <LoadBalancerItem
                        key={lb.id}
                        loadBalancer={lb}
                        expanded={expandedLB === lb.id}
                        onExpand={() => setExpandedLB(expandedLB === lb.id ? null : lb.id)}
                        onOptimize={() => handleOptimizeClick(lb)}
                    />
                ))
            )}

            <VerificationModal
                open={showVerificationModal}
                onClose={handleVerificationCancel}
                onConfirm={handleVerificationConfirm}
            />

            <OptimizationDialog
                open={showOptimizationDialog}
                onClose={() => setShowOptimizationDialog(false)}
                loadBalancer={currentLB}
                optimizationResults={optimizationResults}
                loading={loadingRecommendations}
                errorMessage={errorMessage}
                onStopOptimizing={handleStopOptimizing}
            />
        </Box>
    );
}