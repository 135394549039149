import { createSlice } from '@reduxjs/toolkit';
import { logout } from '../globalActions';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    email: null,
    token: null,
    isAuthenticated: false,
    processedStatus: true,
  },
  reducers: {
    setCredentials: (state, action) => {
      if (action.payload.email !== undefined) {
        state.email = action.payload.email;
      }
      if (action.payload.token !== undefined) {
        state.token = action.payload.token;
      }
      state.isAuthenticated = true;
      if (action.payload.processedStatus !== undefined) {
        state.processedStatus = action.payload.processedStatus;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => ({
      email: null,
      token: null,
      isAuthenticated: false,
      processedStatus: false,
    }));
  }
});

export const { setCredentials } = authSlice.actions;

export default authSlice.reducer;
