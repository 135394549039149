import React from 'react';
import { useSelector } from 'react-redux';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Refresh from 'src/assets/images/refresh.svg';
import 'src/style/graphs.css';

const DashIDAwsmo = () => {
  const { accountId, computeCosts } = useSelector((state) => state.awsAccount);
  const { cost_last_month, cost_past_year } = computeCosts;

  const totalCost = parseFloat(cost_last_month) + parseFloat(cost_past_year);
  const monthlyPercent = (parseFloat(cost_last_month) / totalCost) * 100;
  const yearlyPercent = (parseFloat(cost_past_year) / totalCost) * 100;

  return (
    <>
      <div className='margin-top-dash font-open'>
        <div className={`h-scroller rightContainer`}>
          <div className='m-3 m-lg-4'>
            <div className="bg-white p-3 p-lg-4 desh-height rounded-cust border-purple mb-3 mb-lg-4">
              <div className='d-flex justify-content-between align-items-center mb-3'>
                <div className='text-black f18 font-open font-weight-bold'>AWS Account ID - {accountId}</div>
                <a href="#"><img src={Refresh} className='w24' alt="Refresh" /></a>
              </div>
            </div>

            <div className="bg-white p-3 p-lg-4 desh-height rounded-cust border-purple mb-4">
              <div className='row'>
                <div className='col-12 col-md-12 col-lg-12 col-xl-5 col-xxl-5'>
                  <div className='border-rgt pr-2 h-100'>
                    <div className='text-black font-open font-weight-bold mb-3'>Compute Cost</div>
                    <p className='f14 font-weight-bold mb-1'>Elastic Compute Cloud Cost Yearly</p>
                    <div className='d-flex justify-content-start align-items-center mb-3 mb-md-4'>
                      <div className='col-7 col-md-7'><ProgressBar className='progress-cloud' animated now={yearlyPercent} /></div>
                      <div className='f21 font-weight-bold ps-3 text-black'>$ {cost_past_year}</div>
                    </div>
                    <p className='f14 font-weight-bold mb-1'>Elastic Compute Cloud Cost Monthly</p>
                    <div className='d-flex justify-content-start align-items-center mb-3'>
                      <div className='col-7 col-md-7'><ProgressBar className='progress-cloud' variant="success" animated now={monthlyPercent} /></div>
                      <div className='f21 font-weight-bold ps-3 text-black'>$ {cost_last_month}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-12 col-lg-12 col-xl-7 col-xxl-7'>
                  <div className='text-black font-open font-weight-bold mb-3'>
                    Highlighted Instances for Optimization</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashIDAwsmo;