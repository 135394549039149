import React, { useState, useEffect } from 'react';
import { Modal, Button, Tabs, Tab, Card, Row, Col } from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import InviteUserTab from './InviteUserTab';
import ResetPasswordTab from './ResetPasswordTab';
import 'src/style/Dashboard.css';

const ProfileModal = ({ show, onHide }) => {
  const user = useSelector((state) => state.user);
  const accountId = useSelector((state) => state.awsAccount.accountId);
  const rootUserEmail = useSelector((state) => state.org.rootUserEmail);
  
  const isRootUser = user.email === rootUserEmail;

  // Set initial active tab: "invite" for root users, "reset" for non-root users.
  const [activeTab, setActiveTab] = useState(isRootUser ? 'invite' : 'reset');

  // Enforce that non-root users cannot see the invite tab.
  useEffect(() => {
    if (!isRootUser && activeTab === 'invite') {
      setActiveTab('reset');
    }
  }, [isRootUser, activeTab]);

  return (
    <>
      <Toaster position="top-center" />
      <Modal show={show} onHide={onHide} size="lg" aria-labelledby="profile-modal-title" centered>
        <Modal.Header closeButton>
          <Modal.Title id="profile-modal-title">Profile Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="mb-4">
            <Card.Body>
              <Row>
                <Col md={4}><strong>Account ID:</strong></Col>
                <Col md={8}>{accountId || 'N/A'}</Col>
              </Row>
              <Row className="mt-2">
                <Col md={4}><strong>User Email:</strong></Col>
                <Col md={6}>{user?.email || 'N/A'}</Col>
              </Row>
              {isRootUser ? (
                <Row className="mt-2">
                  <Col md={4}><strong>User Type:</strong></Col>
                  <Col md={8}>ROOT</Col>
                </Row>
              ) : (
                <>
                  <Row className="mt-2">
                    <Col md={4}><strong>Root User Email:</strong></Col>
                    <Col md={8}>{rootUserEmail || 'N/A'}</Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md={4}><strong>User Type:</strong></Col>
                    <Col md={8}>NON ROOT</Col>
                  </Row>
                </>
              )}
            </Card.Body>
          </Card>
          <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className="mb-3">
            {isRootUser && (
              <Tab eventKey="invite" title="Invite User">
                <InviteUserTab accountId={accountId} />
              </Tab>
            )}
            <Tab eventKey="reset" title="Reset Password">
              <ResetPasswordTab onSuccess={onHide} />
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProfileModal;
