// ResetPasswordTab.js
import React, { useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/material';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { authService } from 'src/services/authService';

const ResetPasswordTab = ({ onSuccess }) => {
  const token = useSelector((state) => state.user.token);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isResetting, setIsResetting] = useState(false);

  // States to control the visibility of each password field
  const [showCurrent, setShowCurrent] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error('New passwords do not match.');
      return;
    }
    if (newPassword.length < 6) {
      toast.error('New password must be at least 6 characters long.');
      return;
    }

    setIsResetting(true);
    try {
      const status = await authService.changePassword(
        currentPassword,
        newPassword,
        confirmPassword,
        token
      );
      if (status === 200) {
        toast.success('Password reset successfully!');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        onSuccess();
      } else {
        toast.error('Failed to reset password. Please try again.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to reset password. Please check your current password and try again.');
    } finally {
      setIsResetting(false);
    }
  };

  // Inline styling for the icon button, if needed
  const iconButtonStyle = {
    padding: '0.375rem 0.75rem',
  };

  return (
    <Form onSubmit={handleResetPassword}>
      {/* Current Password Field */}
      <Form.Group controlId="currentPassword">
        <Form.Label>Current Password</Form.Label>
        <InputGroup>
          <Form.Control
            type={showCurrent ? 'text' : 'password'}
            placeholder="Enter current password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />
          <InputGroup.Text>
            <IconButton
              onClick={() => setShowCurrent(prev => !prev)}
              style={iconButtonStyle}
              size="small"
            >
              {showCurrent ? <VisibilityOff fontSize="inherit" /> : <Visibility fontSize="inherit" />}
            </IconButton>
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>

      {/* New Password Field */}
      <Form.Group controlId="newPassword" className="mt-3">
        <Form.Label>New Password</Form.Label>
        <InputGroup>
          <Form.Control
            type={showNew ? 'text' : 'password'}
            placeholder="Enter new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <InputGroup.Text>
            <IconButton
              onClick={() => setShowNew(prev => !prev)}
              style={iconButtonStyle}
              size="small"
            >
              {showNew ? <VisibilityOff fontSize="inherit" /> : <Visibility fontSize="inherit" />}
            </IconButton>
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>

      {/* Confirm New Password Field */}
      <Form.Group controlId="confirmPassword" className="mt-3">
        <Form.Label>Confirm New Password</Form.Label>
        <InputGroup>
          <Form.Control
            type={showConfirm ? 'text' : 'password'}
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <InputGroup.Text>
            <IconButton
              onClick={() => setShowConfirm(prev => !prev)}
              style={iconButtonStyle}
              size="small"
            >
              {showConfirm ? <VisibilityOff fontSize="inherit" /> : <Visibility fontSize="inherit" />}
            </IconButton>
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-3" disabled={isResetting}>
        {isResetting ? 'Resetting...' : 'Reset Password'}
      </Button>
    </Form>
  );
};

export default ResetPasswordTab;
