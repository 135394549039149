import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Paper,
  Button,
  Typography,
  CircularProgress
} from '@mui/material';

const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }).replace(/(\d+)\/(\d+)\/(\d+)/, '$1/$2/$3');
};

const OptimizationDialog = ({
    open,
    onClose,
    loadBalancer,
    optimizationResults,
    loading,
    errorMessage,
    onStopOptimizing
}) => {
    if (!loadBalancer) return null;


  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 2
        }}
      >
        <Typography variant="h6">Optimization Recommendations</Typography>
        {optimizationResults && (
          <Button
            variant="outlined"
            color="error"
            onClick={onStopOptimizing}
            sx={{
              textTransform: 'none',
              borderRadius: 1,
              fontSize: '0.875rem'
            }}
          >
            STOP OPTIMIZING
          </Button>
        )}
      </DialogTitle>
      <DialogContent>
        <Paper
          elevation={1}
          sx={{ p: 3, mb: 3, bgcolor: 'background.paper' }}
        >
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Load Balancer
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', color: 'text.primary' }}
          >
            {loadBalancer.name}
          </Typography>
          <Typography
            variant="caption"
            sx={{ display: 'block', color: 'text.secondary' }}
          >
            {loadBalancer.arn}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Box>
              <Typography variant="subtitle1">Cost Savings</Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  color:
                    optimizationResults && optimizationResults.cost_savings >= 0
                      ? '#2e7d32'
                      : '#d32f2f'
                }}
              >
                {optimizationResults
                  ? `${optimizationResults.cost_savings} USD`
                  : '-'}
              </Typography>
            </Box>
            <Box sx={{ mr: 4 }}>
              <Typography variant="subtitle1">Date</Typography>
              <Typography>
                {optimizationResults
                  ? formatDate(new Date(optimizationResults.date))
                  : '-'}
              </Typography>
            </Box>
          </Box>
        </Paper>

        <Typography
          variant="body1"
          sx={{ mt: 3, textAlign: 'center', fontWeight: 'bold' }}
        >
          Awsmo is optimising this cluster in complete autonomous mode.
        </Typography>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <CircularProgress size={24} />
          </Box>
        )}

        {errorMessage && (
          <Typography
            variant="body1"
            color="error"
            sx={{ mt: 3, textAlign: 'center' }}
          >
            {errorMessage}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default OptimizationDialog;
