// InviteUserTab.js
import React, { useState } from 'react';
import { Form, Button, Row, Col, Dropdown } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { authService } from 'src/services/authService';

const InviteUserTab = ({ accountId }) => {
  const token = useSelector((state) => state.user.token);
  const [inviteEmail, setInviteEmail] = useState('');
  const [isInviting, setIsInviting] = useState(false);
  const [permissionLevel, setPermissionLevel] = useState('0');

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleInvite = async (e) => {
    e.preventDefault();
    if (!emailRegex.test(inviteEmail)) {
      toast.error('Please enter a valid email address.');
      return;
    }
    setIsInviting(true);
    try {
      const response = await authService.sendInvite(inviteEmail, permissionLevel, accountId, token);
      if (response === 201) {
        toast.success('User invited successfully!');
        setInviteEmail('');
        setPermissionLevel('0');
      } else {
        toast.error('Failed to invite user. Please try again.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to invite user. Please try again.');
    } finally {
      setIsInviting(false);
    }
  };

  return (
    <Form onSubmit={handleInvite}>
      <Row>
        <Col md={8}>
          <Form.Group controlId="inviteEmail">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter user's email"
              value={inviteEmail}
              onChange={(e) => setInviteEmail(e.target.value)}
              required
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Label>Permission Level</Form.Label>
          <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-permission-level" style={{ width: '50%' }}>
              {permissionLevel}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: '100%' }}>
              {[...Array(6).keys()].map(level => (
                <Dropdown.Item key={level} onClick={() => setPermissionLevel(level.toString())}>
                  {level}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Button variant="primary" type="submit" className="mt-3" disabled={isInviting}>
        {isInviting ? 'Inviting...' : 'Invite'}
      </Button>
    </Form>
  );
};

export default InviteUserTab;
